import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function Navbar() {
  return (
    <div className='Navbar'>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <div className='navLeft' style={{ width: "15%" }}>
            <img src={require('../photos/Capture-removebg-preview img(1).png')} className='logo' alt='logo' />
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ width: ' width: min-content' }}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{margin:'auto'}}>
              <li className="nav-item">
                <Link className="nav-link active mx-1" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-1" to="/aboutus">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-11" to="/ourclients">Our Clients</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-1" to="/membershipcard">Project</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-1" to="/contact">Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-1" to="/events">Event</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-1" to="/travel">Travel</Link>
              </li>
            </ul>
          </div>
          <div className='navRight'  >
        <ul  >
          <li className='navIcon mx-2'><a href='https://www.facebook.com/dastak.hospitality' > <i className="fa-brands fa-facebook icon" ></i></a> </li>
          <li className='navIcon mx-2'><a href='https://instagram.com/dastakhospitality?igshid=ZDdkNTZiNTM='> <i className="fa-brands fa-instagram icon"></i></a> </li>
          <li className='navIcon mx-2'><a href='#'> <i className="fa-brands fa-linkedin icon"></i> </a> </li>
          <li className='navIcon mx-2' ><Link to='/contact'  > <i className="fa-brands fa-whatsapp icon"  ></i> </Link> </li>
        </ul>
      </div>
        </div>

      </nav>
    

    </div>
  )
}
