import React from 'react'
import './aboutus.css'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';


const Aboutuscard3 = () => {
  const [counterOn, setCounterOn] = useState(false);


  return (
    <div className=' AboutUs'>
      <div className='aboutUs-1'>

        <img src={require('../photos/WhatsApp Image 2023-03-24 at 3.25.06 PM.jpeg')} alt='img' />
        <span   >
          About Us
        </span>
      </div>

      <div className='NoProgress'>
        <div className='NoProgress1'>

 
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn &&
              <CountUp
                start={0}
                end={16}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/ourclients'><button className='countBtn' > <GroupsSharpIcon/> Clients</button></Link>
            </div>
          </ScrollTrigger>

        </div>
        <div className='NoProgress1'>
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn &&
              <CountUp
                start={0}
                end={11}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/ourclients'> <button className='countBtn' > < DescriptionSharpIcon/> Projects</button></Link>
            </div>
          </ScrollTrigger>
        </div>

      </div>
      <div className='aboutUsSummary'>
      <div className='aboutUsSummary1'>
      <div className="aboutUsSummaryImgCont">
        <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.08 PM.jpeg')} alt="" />
        </div>
        <span className='aboutuscard3' style={{ textAlign: 'left' }}><strong> PUBLICITY YOUR GOAL:</strong> Introduce the neighborhood, to a new Manager, New Menu
          or New Decor.<br />
          <strong> OUR STRATEGY:</strong> We market 1 on 1 and can reach up to 20,000 potential Customers
          in Your Area. We deliver your message face to face to every potential Customer, We
          can drive double digit sales growth almost immediately. We increase frequency there by
          retaining of current customer base as well as an upsurge in new customers.
          COMPETITION YOUR GOAL: Fight back against new competitors who are stealing
          Market Share<br />
          <strong> TRAFFIC YOUR GOAL:</strong> Increase Traffic into your outlet. We attract hundreds of new
          customers and bring back many lapsed. Customers to increase traffic.<br />
          <strong> DASTAK PRIVILEGES MASSIVE EXPOSURE </strong>Using Person to Person Advertising in
          the form of ‘HUMAN COMMERCIALS’, DASTAK promotes your Establishment
          exclusively to Homes, Businesses, Corporates and Industries in your Community.<br />
          <strong> INCREASED CUSTOMER BASE</strong>  DASTAK increases local awareness of your establishment, thus introducing NEW
          Customers and enticing old customers to return. DASTAK actively Promotes Customer
          Loyalty by encouraging repeat visits. DEDICATED SALES FORCE Dedicated to
          marketing your business exclusively, a fully trained team to promote and Sell
          your DASTAK Cards. INCREASED SALES Increased customer base leads to increased
          Sales, cash flow and profits for your business TOTAL
          COMMUNICATION DASTAK ADVERTISING provides a full support service with welltrained Executives. TO SUMMARISE The DASTAK Card is a unique approach
          to Advertising, Designed for YOU to give YOU Maximum Exposure and Impact, while
          maintaining Market exclusivity. Daksh advertising is a Group of professionals who dare
          dream and create the big idea. We believe innovative thinking combine with the strength
          of research, knowledge and planning is essential to achieve the desired results in public
          relations. It is our philosophy to be selective in clients, we represent and the criteria we
          use to determine if the client and agency fit begin with goals established compatibility of
          the project the mutual respect. DASTAK PROMOTERS are part of the hand-on
          management team and we treat our clients as we would treat our own Business.

        </span>
      </div>
      </div>

    
    </div>
  )
}
export default Aboutuscard3