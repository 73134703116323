import './footer.css'
import React, { useState } from 'react';

import { send } from 'emailjs-com';

const Footer = () => {


  const [sender_name, set_sender_name] = useState('');
  const [sender_email, set_sender_email] = useState('');
  const [message, set_message] = useState('');


  const handleName = (e) => {
    set_sender_name(e.target.value)

  }
  const handleEmail = (e) => {
    set_sender_email(e.target.value)

  }
  const handlemessage = (e) => {
    set_message(e.target.value)

  }

  const sendMail = (e) => {
    e.preventDefault();
    send(
      'service_zss12is',
      'template_6jc034n',
      { sender_name, sender_email, message },
      'DOsTpdVluJoqGpVMa'
    )
      .then((response) => {
        console.log('message sent successfully ', response.status, response.text)
      })
      .catch((err) => {
        console.log('Failed', err)
      })
    set_sender_name('');
    set_sender_email('');
    set_message('');
  }
  return (
    <div className=' Footer'>
      <div className='upperFooter'>
        <div className='row'>
        <div className='col-md-4'>
          <div className=' commentFooter col'  >
            <h3>
              Our Commitments
            </h3>
            <span>
              We take the service we provide to our clients very seriously, and we start every engagement by establishing a thorough understanding of the client’s ambitions and goals. At Dastak Hospitality, we are committed to providing smart solutions that can result in a 35 to 40% revenue increase to your bottom line. Our solutions will also offer a high mind share as well as better brand recall factor to you, at no extra cost.
            </span>
          </div>
          </div>
          <div className='col-md-4'> 
          <div className='contactFooter col ' name='contact' style={{ height: "100%" }} >
            <div className='row'>
              <div className="contactFooter-form">
                <form onSubmit={sendMail}>
                  <h3>Get In Touch</h3>
                  <div className="singleItem">
                    <input type='text'
                      className='name'
                      value={sender_name}
                      onChange={handleName}
                      name='sender_name'
                      placeholder='Name...'

                    />
                  </div>

                  <div className="singleItem">

                    <input type='email'
                      name='sender_email'
                      value={sender_email}
                      onChange={handleEmail}
                      className='email'
                      placeholder='Email...'

                    />
                  </div>




                  <div className='textArea singleItem'>


                    <textarea
                      id=""
                      name='message'
                      value={message}
                      onChange={handlemessage}
                      cols='30' rows='3'
                      placeholder="Leave your Message..."

                    ></textarea>
                  </div>
                  <div className="msg" >
                    {send && "Message has been sent"}
                  </div>
                  <button type="submit" className="btn btn-primary"  >Submit</button>
                </form>
              </div>
            </div>
          </div>
          </div>
          <div className='col-md-4'> 
          <div className='recentPostFooter col ' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <div className="card  " style={{ backgroundColor: 'transparent',  }}>
              <div className="row g-0">
                <div className="col-md-4"  >
                  <img style={{ height: 'auto' }} src={require('../photos/membershipCard/WhatsApp Image 2023-03-26 at 2.35.48 PM.jpeg')} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8"  >
                  <div className="card-body">
                    <h5 className="card-title">Uma Club & Resort</h5>
                    <p className="card-text">Karhi Road, pateri, Satna, Madhya Pradesh-485001</p>
                    <p className="card-text"><small className="text-muted">Latest Membership Card </small></p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className=' lowerFooter' >
        <span>
          All Rights Reserved, 2006. Dastak Hospitality and Promoters .Site designed by DigSol Infotech
        </span>
      </div>
    </div>
  )
}
export default Footer