import React from 'react';
import './travel.css'
export default function travel() {

    return (
        <div className=" travel">
            <div className="travelBanner">
                <div className="travelBannerContent">
                    <h1>DASTAK INDIA TOURS</h1>
                    <p>Explore India, Customize Tour Packages, Lowest Rates, Guaranteed Services.</p>
                </div>
                <div className="travelBannerImg">
                    <img src={require("../photos/travel/travel-banner.jpg")} alt="" />
                </div>
            </div>
            <div className="container">
                <div className=" travelPhotoVideo travelPdfs">
                    <h2 className="title">Char Dham Yatra</h2>
                    <p>It is believed that by undertaking this pilgrimage, one can ascend to the abodes of the gods and attain salvation. The four holy sites that make up the Char Dham Yatra are <strong> YAMUNOTRI, GANGOTRI, KEDARNATH, and BADRINATH.</strong></p>
                    <div className="row">
                        <div className="col-md-4">
                            <a href={require("../photos/travel/char-dham-yatra-1.jpeg")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/char-dham-yatra-1.jpeg")} alt="" />
                                    </div>
                                    {/* <div className="pdfsTitle">
                                        <h4>2 Dham Yatra</h4>
                                    </div> */}
                                    <div className="openPdf">
                                        <span><i class="fa-regular fa-eye"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href={require("../photos/travel/char-dham-yatra-2.jpeg")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/char-dham-yatra-2.jpeg")} alt="" />
                                    </div>
                                    {/* <div className="pdfsTitle">
                                        <h4>4 Dham Yatra</h4>
                                    </div> */}
                                    <div className="openPdf">
                                        <span><i class="fa-regular fa-eye"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href={require("../photos/travel/char-dham-yatra-video.mp4")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/char-dham-yatra-video-1.PNG")} alt="" />
                                    </div>
                                    <div className="openPdf">
                                    <span><i class="fa-regular fa-circle-play"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="border"></div>

                <div className="travelPdfs">
                    <h2 className="title">Packages</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <a href={require("../photos/travel/DO DHAM YATRA.pdf")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/do-dham.PNG")} alt="" />
                                    </div>
                                    <div className="pdfsTitle">
                                        <h4>Do Dham Yatra</h4>
                                    </div>
                                    <div className="openPdf">
                                        <span>See Packages <i class="fa-solid fa-arrow-right"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href={require("../photos/travel/char dham yatra.pdf")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/char-dham.PNG")} alt="" />
                                    </div>
                                    <div className="pdfsTitle">
                                        <h4>Char Dham Yatra</h4>
                                    </div>
                                    <div className="openPdf">
                                        <span>See Packages <i class="fa-solid fa-arrow-right"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href={require("../photos/travel/Kedarnath Yatra.pdf")} target="_blank" >
                                <div className="card">
                                    <div className="pdfsImg">
                                        <img src={require("../photos/travel/kedarnath.PNG")} alt="" />
                                    </div>
                                    <div className="pdfsTitle">
                                        <h4>Kedarnath</h4>
                                    </div>
                                    <div className="openPdf">
                                        <span>See Packages <i class="fa-solid fa-arrow-right"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="border">
                </div>

                <div className="travelContents">
                    <div className="travelContent">
                        <div className="travelContentImg">
                            <img src={require("../photos/Company logo/20230324_112112_0000.png")} alt="" />
                        </div>
                        <div className="travelContentText">
                            <p>We are one of the best Travel Agents in India that provide customised tour packages according to your preference. Come and explore the places that you always wished to see! .</p>
                        </div>
                    </div>
                    <div className="travelContent">
                        <div className="travelContentText">
                            <p>"We provide holiday, tour and travel packages for users, and companies. We deal in Honeymoon package, Family tour, Corporate tour, Domestic holiday package, International holiday package. Also we provide service for flight ticket booking at lowest cost."</p>
                        </div>
                        <div className="travelContentImg">
                            <img src={require("../photos/travel/family-travel-bus-tour.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="travelContent">
                        <div className="travelContentImg">
                            <img src={require("../photos/travel/buses.png")} alt="" />

                        </div>
                        <div className="travelContentText">
                            <h3>Transport</h3>
                            <p>We are the number one Travel Agent in India, providing our clients with the best from hotel bookings to transportation. You can avail of mini-coaches, luxury coaches, and cars to make each plan special.</p>
                        </div>
                    </div>
                </div>

                <div class="border">
                </div>
            </div>
        </div>
    )
}
