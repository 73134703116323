import HorizontalScroll from 'react-scroll-horizontal';
import './home.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useRef, useState } from "react";
import Home7 from './Home7';
import Home2 from './Home2';



const Home = () => {






  return (
    <div className='Home'>
      <div className='home1'  >

        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">

            <div className="carousel-item   ">
              <img src={require('../photos/home1-Images/banner1(1).png')} alt="" />
            </div>
            <div className="carousel-item active ">
              <img src={require('../photos/home1-Images/WhatsApp Image 2023-04-07 at 9.25.35 AM.jpeg')} alt="" />
            </div>
            <div className="carousel-item  ">
              <img src={require('../photos/home1-Images/118164421.jpg')} className="d-block w-100" alt="..." />
            </div>
            {/* <div className="carousel-item">
              <img src={require('../photos/home1-Images/1632309655_columbia1.jpg')} className="d-block w-100" alt="..." />
            </div> */}
            <div className="carousel-item">
              <img src={require('../photos/home1-Images/banner3.png')} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={require('../photos/home1-Images/banner4.png')} className="d-block w-100" alt="..." />
            </div>

            <div className="carousel-item">
              <img src={require('../photos/home1-Images/WhatsApp Image 2023-03-29 at 12.26.07 PM (3).jpeg')} alt="" />
            </div>
            <div className="carousel-item">
              <img src={require('../photos/home1-Images/WhatsApp Image 2023-03-29 at 12.26.07 PM (4).jpeg')} alt="" />
            </div>


          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>


      </div>



      {/* horizontal scroll home 2*/}

      <Home2 />
      {/* home 3 */}
      <div className="container">
      <div className='home3'>
        <div className='home3Title '>
          <h2 className='title '>
            Welcome to Dastak Hospitality
          </h2>
        </div>
        <div className='shortInfoHome3'>

          <p>
            Operating under the name of <strong>  DASTAK HOSPITALITY AND PROMOTERS</strong> in our experience, the most effective way to
            market one's services is through a live personal, Presentation on behalf of our clients,
            we personally introduce ourselves to their customers on behalf of our clients in order to
            establish relationships with them.<br />
            <strong> DASTAK HOSPITALITY </strong>established in October 2006 by Om Chouhan Dastak.
            Hospitality is a leading sales and marketing services company. Our head office is in
            New Delhi INDIA.
            We have executed Marketing Campaigns for <strong> STAR CATEGORY HOTELS, RESORTS,
              RESTAURANTS, CLUB, WATER AMUSEMENT PARK, MULTIPLEXES, SALONS
              AND FAMILY ENTERTAINMENT PLACES IN ALLOVER INDIA.</strong>
          </p>
        </div>
      </div>

      <div class="border"></div>

      <div className=" travelPhotoVideo travelPdfs">
          <h2 className="title">Char Dham Yatra</h2>
          <p>It is believed that by undertaking this pilgrimage, one can ascend to the abodes of the gods and attain salvation. The four holy sites that make up the Char Dham Yatra are <strong> YAMUNOTRI, GANGOTRI, KEDARNATH, and BADRINATH.</strong></p>
          <div className="row">
            <div className="col-md-4">
              <a href={require("../photos/travel/char-dham-yatra-1.jpeg")} target="_blank" >
                <div className="card">
                  <div className="pdfsImg">
                    <img src={require("../photos/travel/char-dham-yatra-1.jpeg")} alt="" />
                  </div>
                  {/* <div className="pdfsTitle">
                                        <h4>2 Dham Yatra</h4>
                                    </div> */}
                  <div className="openPdf">
                    <span><i class="fa-regular fa-eye"></i></span>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a href={require("../photos/travel/char-dham-yatra-2.jpeg")} target="_blank" >
                <div className="card">
                  <div className="pdfsImg">
                    <img src={require("../photos/travel/char-dham-yatra-2.jpeg")} alt="" />
                  </div>
                  {/* <div className="pdfsTitle">
                                        <h4>4 Dham Yatra</h4>
                                    </div> */}
                  <div className="openPdf">
                    <span><i class="fa-regular fa-eye"></i></span>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a href={require("../photos/travel/char-dham-yatra-video.mp4")} target="_blank" >
                <div className="card">
                  <div className="pdfsImg">
                    <img src={require("../photos/travel/char-dham-yatra-video-1.PNG")} alt="" />
                  </div>
                  <div className="openPdf">
                    <span><i class="fa-regular fa-circle-play"></i></span>
                  </div>
                </div>
              </a>
            </div>
          </div>
       <a href="/travel">  <button type="button" class="btn btn-primary w-100 my-4 mx-0">Explore more</button></a> 
        </div>

      <div class="border"></div>

      {/* home4 card */}
      <div className='home4'>
        <div className='wrapper'>
          <div className='FullCard'>
            <h4 className='titOfHomeCard' style={{ textAlign: 'center', color: '#A33E00', fontWeight: 'bold' }} >WHO ARE WE?</h4>
            <div className='card'>

              <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.08 PM.jpeg')} />
              <div className='info'>

                <h5>  WHO WE ARE? </h5>
                <p>Operating under the name of DASTAK HOSPITALITY AND PROMOTERS (An
                  Advertising and Marketing Company)...</p>
                <Link to='/aboutus'> <button className='btn'>  Read More<NavigateNextIcon /></button></Link>
              </div>
            </div>
          </div>


          <div className='FullCard'>
            <h4 className='titOfHomeCard' style={{ textAlign: 'center', color: '#A33E00', fontWeight: 'bold' }} >WHY ARE WE HERE?</h4>
            <div className='card'>
              <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.09 PM (1).jpeg')} />
              <div className='info'>

                <h5> WHY ARE WE HERE? </h5>
                <p> It's not always possible or practical to tailor a sales message to the specific...</p>
                <Link to='/aboutuscard2'> <button className='btn'>  Read More<NavigateNextIcon /></button></Link>
              </div>
            </div>
          </div>

          <div className='FullCard'>
            <h4 className='titOfHomeCard' style={{ textAlign: 'center', color: '#A33E00', fontWeight: 'bold' }} >HOW IT IS DONE?</h4>
            <div className='card'>
              <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.10 PM.jpeg')} />
              <div className='info'>

                <h5>  HOW IT IS DONE? </h5>
                <p> We market 1 on 1 and can reach up to 20,000 potential Customers
                  in Your Area. We deliver...</p>
                <Link to='/aboutuscard3'> <button  >  Read More<NavigateNextIcon /></button></Link>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="border"></div>

      {/* Home 5 gallary button */}
      <div className='home5'>
        <img src={require('../photos/White Grey Minimalist Home Sale (Flyer).png')} alt="" />
        {/* <Link to='photoGallary'>  <button type="submit" className="home5GallaryBtn my-3" >Gallary</button></Link> */}
        <div className="container">
          <h2 class="title">Our Projects</h2>
          <Link to='/membershipcard'>  <button className="button-six clip-path" type="submit"> <ArrowForwardIcon /></button></Link>
        </div>
      </div>

      <div class="border"></div>

      {/* Home 6  */}
      </div>

      {/* home7 */}
      <Home7 />


    </div>
  )

}
export default Home;