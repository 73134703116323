import React from 'react'
import './ourclient.css'
export default function OurClients() {
  return (
    <div className=' OurClients'>
        <div className='ourClientHeadImage' style={{width:'100%'}}>   
          <div className="ourClientHeadImageTitleImg"> 
          <img src = {require('../photos/kisspng-customer-relationship-management-business-company-sold-out-5ac6f5345be055.5354670615229883403763.png')} alt='img'/>
        </div>
        <div className='ourClientHeadImageTitle'> 
          <span >OUR CLIENTS</span>
          </div>
        </div>

       
        <div className='ourClientDescription'>
        <div className="container">
          <span><strong> COMMUNICATION DASTAK HOSPITALITY</strong> provides a full support service with well-trained Executives. TO SUMMARISE The DASTAK Card is a unique approach to Advertising, Designed for YOU to give YOU Maximum Exposure and Impact, while maintaining Market exclusivity.  Dastak Hospitality is a Group of professionals who dare dream and create the big idea. We believe innovative thinking combine with the strength of research, knowledge and planning is essential to achieve the desired results in public relations. It is our philosophy to be selective in clients, we represent and the criteria we use to determine if the client and agency fit begin with goals established compatibility of the project the mutual respect. DASTAK HOSPITALITY are part of the hand-on management team and we treat our clients as we would treat our own Business.</span>
        </div>
        </div>
        <div className="container">
        <div className='ourClientImages'>
 
          <div className='row'> 
          <h2 className ='title text-center'>Our Clients</h2>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/one.jpg')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/two.jpg')} alt='img'/>
          </div>
          {/* <div class="w-100 d-none d-md-block"></div> */}
          <div className='col-sm-3'>
            <img src={require('../photos/home2/three.jpg')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/four.png')} alt='img'/>
          </div>
      
          <div className='col-sm-3'>
            <img src={require('../photos/home2/five.png')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/six.jpg')} alt='img'/>
          </div>
       
          <div className='col-sm-3'>
            <img src={require('../photos/home2/seven.png')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/eight.jpg')} alt='img'/>
          </div>
       
          <div className='col-sm-3'>
            <img src={require('../photos/home2/nine.jpg')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/ten.png')} alt='img'/>
          </div>
      
          <div className='col-sm-3'>
            <img src={require('../photos/home2/eleven.jpg')} alt='img'/>
          </div>
          <div className='col-sm-3'>
            <img src={require('../photos/home2/thirteen.png')} alt='img'/>
          </div>
        
          <div className='col-sm-3'>
            <img src={require('../photos/home2/twelve.jpg')} alt='img'/>
          </div>
          </div>     
          </div>    
        </div>
    </div>
  )
}
