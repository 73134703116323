import React, { useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { send } from 'emailjs-com';
import './contactus.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import EmailIcon from '@mui/icons-material/Email';

// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
const Contact = () => {



  const [sender_name, set_sender_name] = useState('');
  const [sender_email, set_sender_email] = useState('');
  const [sender_number, set_sender_number] = useState('');
  const [sender_address, set_sender_address] = useState('');
  const [message, set_message] = useState('');



  const handleName = (e) => {
    set_sender_name(e.target.value)

  }
  const handleEmail = (e) => {
    set_sender_email(e.target.value)

  }

  const handleNumber = (e) => {
    set_sender_number(e.target.value)

  }

  const handleAddress = (e) => {
    set_sender_address(e.target.value)

  }
  const handlemessage = (e) => {
    set_message(e.target.value)

  }

  const sendMail = (e) => {
    e.preventDefault();
    send(
      'service_zss12is',
      'template_6jc034n',
      { sender_name, sender_email, sender_number, sender_address, message },
      'DOsTpdVluJoqGpVMa'
    )
      .then((response) => {
        console.log('message sent successfully ', response.status, response.text)
      })
      .catch((err) => {
        console.log('Failed', err)
      })
    set_sender_name('');
    set_sender_email('');
    set_sender_number('');
    set_sender_address('');
    set_message('');
  }

  return (
    <div className=' Contact'>
      <div className='contactImage'>
        <img src={require('../photos/Contact Us Image/contact-us.jpg')} alt="" />
      </div>
      <div className='ConatactMid'>




        {/* <div className='contactForm ' name='contact'   >
      


            <div className="contactForm-form">
              <form onSubmit={sendMail}>

                <h1>Get In Touch</h1>

                <div className="singleItem">

                  <input type='text'
                    className='name'
                    value={sender_name}
                    onChange={handleName}
                    name='sender_name'
                    placeholder='Name...'

                  />
                </div>

                <div className="singleItem">

                  <input type='number'
                    className='number'
                    value={sender_number}
                    onChange={handleNumber}
                    name='sender_number'
                    placeholder='Number...'

                  />
                </div>

                <div className="singleItem">

                  <input type='email'
                    name='sender_email'
                    value={sender_email}
                    onChange={handleEmail}
                    className='email'
                    placeholder='Email...'

                  />
                </div>

                <div className="singleItem">

                  <input type='text'
                    name='sender_address'
                    value={sender_address}
                    onChange={handleAddress}
                    className='address'
                    placeholder='Address...'

                  />
                </div>




                <div className='textArea singleItem'>


                  <textarea
                    id=""
                    name='message'
                    value={message}
                    onChange={handlemessage}
                    cols='30' rows='3'
                    placeholder="Leave your Message..."

                  ></textarea>
                </div>
                <div className="msg" >
                  {send && "Message has been sent"}
                </div>
                <button type="submit" className="contactBtn" >Submit</button>
              </form>
            </div>
          </div> */}




        <div className='SendQueryForm' name='contact'>
          <div className="contactForm-form">
            <form onSubmit={sendMail}>
              <div className="form-floating mb-3">
                <input type="name" value={sender_name} onChange={handleName} name='sender_name' className="form-control" id="floatingInput" placeholder="Name.." />
                <label htmlFor="floatingInput">Name</label>
              </div>
              <div className="form-floating mb-3">
                <input type="email" name='sender_email' value={sender_email} onChange={handleEmail} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating mb-3">
                <input type="number" value={sender_number} onChange={handleNumber} name='sender_number' className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Number</label>
              </div>
              <div className="form-floating mb-3">
                <input type="text" name='sender_address' value={sender_address} onChange={handleAddress} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Address</label>
              </div>

              <div className="form-floating">
                <textarea className="form-control" name='message' value={message} onChange={handlemessage} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                <label htmlFor="floatingTextarea2">Comments</label>
              </div>

              <div className="msg" >
                {send && "Message has been sent"}
              </div>
              <button type="submit" className="mx-0 w-100 my-3 btn btn-primary" >Submit</button>
            </form>

          </div>
        </div>


        <div className='contactGif'>
          <img src={require('../photos/Contact Us Image/129404-contact-us.gif')} alt="" />
        </div>
      </div>


      <div className='contactDetails'>
        <ul className='contactInfo'>
          <li className='ContactAdd'>
            <h3><strong><LocationOnIcon/>Address:</strong></h3>
            <span>1116, 11TH Floor, Kirti Shikhar Tower, Janakpuri District Centre, Janakpuri west, New Delhi-110058</span>
          </li>
          <li className='ContactAdd'>
            <h3><strong> < PhoneAndroidIcon />  Mobile: </strong>
            </h3>
            <span>  <WhatsAppIcon/>8377885007,  <LocalPhoneIcon/>9821105007</span>
          </li>
          <li className='ContactAdd'>
            <h3><strong><EmailIcon />  Email: </strong></h3>
            <span>  info@dastakhospitality.com / care.dastakhospitality@gmail.com  </span>
          </li>
          <li className='ContactAdd'>
           <h3><strong><LanguageOutlinedIcon />  Website: </strong></h3>
            <span>  www.dastakhospitality.com </span>
          </li>

        </ul>
        {/* <div className='contacMap'>

           

        </div> */}
      </div>

    </div>
  )
}
 
export default  Contact