import React from 'react'
import './home2.css'
const Home2 = () => {
  return (
    <div className='Home2'>
        <div className="slider">
            <div className="slide-track">
                <div className="slide">
                    <img src={require('../photos/home2/eight.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/eleven.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/five.png')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/nine.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/four.png')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/one.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/seven.png')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/six.jpg')} alt="" />
                </div>
               
                <div className="slide">
                    <img src={require('../photos/home2/ten.png')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/thirteen.png')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/three.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/twelve.jpg')} alt="" />
                </div>
                <div className="slide">
                    <img src={require('../photos/home2/two.jpg')} alt="" />
                </div>
                 
            </div>
        </div>
    </div>
  )
}

export default Home2