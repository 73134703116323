// import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import './events.css';
export default function App() {
  return (
    <div className="Events">


      <div className="eventHead">
        <video
          src={require('../photos/Events/whatsapp-video-2023-04-15-at-45847-pm_hVFBgaVe.mp4')}
          autoPlay
          loop
        />

        <div className="eventTit">
          <span>  Welcome <br /> <h4> Scroll Down to checkout our Events</h4></span>
        </div>
      </div>






      <div className="eventHead2">
        <div className="row">
        <div className="col-6">
            <div className="ratio ratio-16x9">
              <img
                src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.24 PM.jpeg')}
                title="Vimeo video"


                controls
              ></img>
            </div>

          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.58.56 PM.mp4')}
                title="Vimeo video"


                controls
              ></video>
            </div>
          </div>

          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.23.10 PM.mp4')}
                title="Vimeo video"


                controls

              ></video>
            </div>
          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.22.43 PM.mp4')}
                title="Vimeo video"


                controls
              ></video>
            </div>
          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.24.04 PM.mp4')}
                title="Vimeo video"


                controls
              ></video>
            </div>
          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.26.18 PM.mp4')}
                title="Vimeo video"


                controls
              ></video>
            </div>
          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <video
                src={require('../photos/Events/WhatsApp Video 2023-04-15 at 4.58.56 PM.mp4')}
                title="Vimeo video"


                controls
              ></video>
            </div>

          </div>
          <div className="col-6">
            <div className="ratio ratio-16x9">
              <img
                src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.23 PM (1).jpeg')}
                title="Vimeo video"


                controls
              ></img>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}



