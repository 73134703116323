import React from 'react'
import './home7.css'
const home7 = () => {
  return (
    <div className="home7">
      <span>Gallery</span>
      <div className="home7downArrow">
        <img src={require('../photos/77499-down.gif')} alt="" />
      </div>
      <div id="carouselExampleRide" className="carousel slide" data-bs-ride="true">
        <div className="carousel-inner">
        <div className="carousel-item active">
            <img src={require('../photos/WhatsApp Image 2023-03-24 at 3.28.32 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item  ">
            <img src={require('../photos/WhatsApp Image 2023-04-07 at 9.26.14 AM (1).jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item  ">
            <img src={require('../photos/WhatsApp Image 2023-04-07 at 9.26.14 AM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item  ">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.10 PM (1).jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item  ">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.11 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.12 PM (1).jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.14 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.13 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.15 PM (1).jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.19 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.15 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          {/* <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.17 PM (1).jpeg')} className="d-block w-100" alt="..." />
          </div> */}
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.18 PM.jpeg')} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.20 PM (1).jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.20 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.21 PM (1).jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.21 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.22 PM (1).jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.22 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.23 PM (1).jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.23 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.24 PM.jpeg')} alt="" />
          </div>
          
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.48 PM.jpeg')} alt="" />
          </div>
          <div className="carousel-item">
            <img src={require('../photos/WhatsApp Image 2023-03-24 at 3.28.27 PM.jpeg')} alt="" />
          </div>









        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default home7