import React from 'react'
import './membershipcard.css'
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { Link } from 'react-router-dom';
import Project from './Project';
export default function MembershipCards() {
  return (
    <div className=' MembershipCards'>
      <div className="LatestProject " style={{width:'100%', display:'flex',justifyContent:'center'}}>
        <span>Our Running Project</span>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div id="container">
            <div className="product-details">
              <h1>Regenta Inn Jaipur</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">B-31, Govind Marg, Adarsh Nagar, Rajapark, Jaipur - 302004 Rajasthan, India </p>



              <div className="control">

                <button className="btn">
                  <span className="price"><CallIcon/></span>
                  <span className="shopping-cart"><PhoneForwardedIcon aria-hidden="true"></PhoneForwardedIcon></span>
                 <Link to='/contact'>  <span className="buy"> Call now</span></Link>
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src={require('../photos/membershipCard/WhatsApp Image 2023-04-22 at 2.00.58 PM.jpeg')} alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li><strong> Regenta Inn Jaipur</strong></li>
                  <li><strong>Loc:</strong> Jaipur</li>
                  <li>Contact: 9549623234<br/>9549723234</li>
                  <li>gm.rij@royalorchidhotels.com </li>
                  <li>www.royalorchidhotels.com</li>

                </ul>
              </div>
            </div>
          </div>
        </div>


{/* card 2 */}
        <div className='col-6'>
          <div id="container">

            <div className="product-details">

              <h1>Uma Club & Resort</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">Karhi Road, pateri, Satna, Madhya Pradesh-485001 </p>



              <div className="control">

                <button className="btn">
                  <span className="price"><CallIcon/></span>
                  <span className="shopping-cart"><PhoneForwardedIcon aria-hidden="true"></PhoneForwardedIcon></span>
                  <Link to='/contact'> <span className="buy"> Call now</span></Link> 
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src={require('../photos/membershipCard/WhatsApp Image 2023-03-26 at 2.35.48 PM.jpeg')} alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li><strong>Uma Club & Resort</strong> </li>
                  <li><strong>Loc: </strong> Madhya Pradesh</li>
                  <li><strong>Contact: </strong> 9300022333,<br/>7470949119</li>
                  <li>  ucr.satna@gmail.com</li>
                  <li>  www.uma-resort.com</li>

                </ul>
              </div>
            </div>
          </div>
        </div>


        {/* <div className='col-6'>
          <div id="container">

            <div className="product-details">

              <h1>Pagoda</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">Capmpaign Organised By:Dastak Hospitality<br/> 22, Ravinder path,B-Block, Shri Ganganagar, Rajasthan-335001</p>



              <div className="control">

                <button className="btn">
                  <span className="price">₹....</span>
                  <span className="shopping-cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                  <span className="buy"> Call now</span>
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src={require('../photos/membershipCard/WhatsApp Image 2023-03-26 at 2.41.18 PM.jpeg')} alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li><strong>Pagoda </strong> </li>
                  <li> Rajasthan</li>
                  <li><strong>Mob: </strong>9462742125</li>
                  <li><strong>Phone: </strong>01542442125,<br/>2443125</li>
                  <li> pagodaresortsinn@gmail.com</li>
                  <li> www.pogadaresortsinn.in</li>

                </ul>
              </div>
            </div>
          </div>
        </div>

 */}


        <div className="w-100 d-none d-md-block"></div>
        {/* 2nd row */}



        {/* <div className='col-6'>
          <div id="container">

            <div className="product-details">

              <h1>Le Amor</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">Capmpaign Organised By:Dastak Hospitality.<br/>8, Jhalawar Road Kota, Rajasthan-324007</p>



              <div className="control">

                <button className="btn">
                  <span className="price">₹....</span>
                  <span className="shopping-cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                  <span className="buy"> Call now</span>
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src={require("../photos/membershipCard/WhatsApp Image 2023-03-26 at 2.39.21 PM.jpeg")} alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li><strong>Le Amor</strong> </li>
                  <li><strong>Loc:</strong> Kota</li>
                  <li><strong>Ph:</strong>0744-2360890,<br/>9414189890,<br/>9414183085</li>
                  <li> hotelleamor@gmail.com</li>
                  <li>  www.hotelleamor.com</li>

                </ul>
              </div>
            </div>
          </div>
        </div> */}



        {/* card 4 */}
        


        {/* <div className="w-100 d-none d-md-block"></div>
        <div className='col-6'>
          <div id="container">

            <div className="product-details">

              <h1>CHRISTMAS TREE</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">Capmpaign Organised By: Dastak Hospitality</p>



              <div className="control">

                <button className="btn">
                  <span className="price">₹....</span>
                  <span className="shopping-cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                  <span className="buy"> Call now</span>
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src="https://images.unsplash.com/photo-1606830733744-0ad778449672?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzl8fGNocmlzdG1hcyUyMHRyZWV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li>Shade: Olive green</li>
                  <li>Decoratio: balls and bells</li>
                  <li>Materia: Eco-Friendly</li>
                  <li>Height: 5 Ft </li>

                </ul>
              </div>
            </div>
          </div>
        </div>



        <div className='col-6'>
          <div id="container">

            <div className="product-details">

              <h1>CHRISTMAS TREE</h1>
              <span className="hint-star star">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>

              <p className="information">" Let's spread the joy , here is Christmas , the most awaited day of the year.Christmas Tree is what one need the most. Here is the correct tree which will enhance your Christmas.</p>



              <div className="control">

                <button className="btn">
                  <span className="price">₹....</span>
                  <span className="shopping-cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                  <span className="buy"> Call now</span>
                </button>

              </div>

            </div>

            <div className="product-image">

              <img src="https://images.unsplash.com/photo-1606830733744-0ad778449672?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzl8fGNocmlzdG1hcyUyMHRyZWV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />


              <div className="info">
                <h2>Details</h2>
                <ul>
                  <li>Shade: Olive green</li>
                  <li>Decoratio: balls and bells</li>
                  <li>Materia: Eco-Friendly</li>
                  <li>Height: 5 Ft </li>

                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="projects">
        <Project/>
      </div>




    </div>
  )
}
