import Navbar from "./Components/Navbar/Navbar";
import AboutUs from "./Components/Pages/AboutUs";
import AboutUsCard2 from "./Components/Pages/AboutUsCard2";
import AboutUsCard3 from "./Components/Pages/AboutUsCard3";
import Contact from "./Components/Pages/Contact";
import Home from "./Components/Pages/Home";
import MembershipCards from "./Components/Pages/MembershipCards";
import OurClients from "./Components/Pages/OurClients";
import Events from './Components/Pages/Events'
import Travel from './Components/Pages/travel'
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from 'react-router-dom';
import Footer from "./Components/Footer/Footer";
 
import Project from "./Components/Pages/Project";
function App() {
  return (
    <div className="App">

      <Router>
        <Navbar className='NavBar' />
        <div className="pages"> 
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/aboutus' element={<AboutUs />} />
          <Route exact path='/aboutuscard2' element={<AboutUsCard2 />} />
          <Route exact path='/aboutuscard3' element={<AboutUsCard3 />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/membershipcard' element={<MembershipCards />} />
          <Route exact path='/ourclients' element={<OurClients />} />
          <Route exact path='/projects' element={<Project />} />
          <Route exact path='/events' element={<Events/>} />
          <Route exact path='/travel' element={<Travel/>} />
        </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
