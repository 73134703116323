import React from 'react'
import './aboutus.css'
// import './aboutuscard2.css'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';

const Aboutuscard2 = () => {
  const [counterOn, setCounterOn] = useState(false);
 
  return (
    <div className=' AboutUs'>
      <div className='aboutUs-1'>

        <img src={require('../photos/WhatsApp Image 2023-03-24 at 3.25.06 PM.jpeg')} alt='img' />
        <span>
          About Us
        </span>
      </div>

      <div className='NoProgress'>
        <div className='NoProgress1'>


          <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn &&
              <CountUp
                start={0}
                end={16}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/ourclients'><button className='countBtn' > <GroupsSharpIcon /> Clients</button></Link>
            </div>
          </ScrollTrigger>

        </div>
        <div className='NoProgress1'>
          <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn &&
              <CountUp
                start={0}
                end={11}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/ourclients'> <button className='countBtn' > < DescriptionSharpIcon /> Projects</button></Link>
            </div>
          </ScrollTrigger>
        </div>

      </div>
      <div className='aboutUsSummary'>
        <div className='aboutUsSummary1'  >
          <div className="aboutUsSummaryImgCont">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.09 PM (1).jpeg')} alt="" />
          </div>
          <span    style={{  display: "flow-root",    width: "fit-content"}}>
            It's not always possible or practical to tailor a sales message to the specific needs of
            each individual prospect you wish to serve, but we believe there's a place for directed
            planning in your overall strategy, and we believe it's imperative when you're seeking the
            attention of your Top-tier prospects. Our unique Face-to-Face approach markets your
            brand exclusively to consumers in your local neighborhood. We raise customer
            awareness of your brand and introduce new customers into your establishment. We
            remind your old customers to return and actively promote customer loyalty by
            encouraging repeat visits. We have dedicated Marketing Professionals and Experienced
            Managers to provide full support services for every Campaign. We assist clients with
            our well trained Customer Care executives. Using the most cost-efficient advertising
            approach there is in the market, we are proud of our ever-increasing client list that
            continues to seek our services <strong> since 2006.</strong>

          </span>
        </div>
      </div>


    </div>
  )
}
export default Aboutuscard2