import React from 'react'
import './aboutus.css'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';

const AboutUs = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className=' AboutUs'>

      <div className='aboutUs-1'>

        <img src={require('../photos/WhatsApp Image 2023-03-24 at 3.25.06 PM.jpeg')} alt='img' />
        <span   >
          About Us
        </span>
      </div>

<div className="container">
      <div className='NoProgress'>
        <div className='NoProgress1'>
          <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
            {counterOn &&
              <CountUp
                start={0}
                end={16}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/ourclients'><button className='countBtn' > <GroupsSharpIcon/> Clients</button></Link>
            </div>
          </ScrollTrigger>

        </div>
        <div className='NoProgress1'>
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn &&
              <CountUp
                start={0}
                end={11}
                duration={0.75}
                delay={0}
                separator=" "
                // decimals={0}
                // decimal=","
                // prefix="EUR "
                suffix="+"

              />}



            <div className='CountInfo'>

              <Link to='/projects'> <button className='countBtn' > < DescriptionSharpIcon/> Projects</button></Link>
            </div>
          </ScrollTrigger>
        </div>

      </div>


      <div className='aboutUsSummary'>
        <div className='aboutUsSummary1'>
          <span> <strong>  “Dस्तक Hospitality and Promoters”</strong>  Direct
            marketing promotion for your Hotel. This entire exercise will be done “WITHOUT” any
            monetary investment from your organization! <br />
            Let me share our successful methodology, which is WIN–WIN situation for all the
            people concerned, namely your hotel, the Guest & us.
            As you are aware the business community in hospitality industry across the world tries
            to source business by advertising about themselves, their products or services. But the
            process adopted by them results in very high investment through electronic medium,
            like T.V, & other audio-visual means, which are not always proven to keep their brand in
            the customers mind for a long time. Eventually it is a very costly method, which needs to
            be repeated regularly to obtain the desired outcome.

          </span>
          <div className="aboutUsSummaryImgCont">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.08 PM (1).jpeg')} alt="" />
          </div>
        </div>
        <div className='aboutUsSummary1'>
          <div className="aboutUsSummaryImgCont">
            <div className="aboutUsSummary1-1" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={require('../photos/home1-Images/bgfdxsz.jpeg')} alt="" />

            </div>
            <div className="aboutUsSummary1-2" style={{ height: "150px", width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={require('../photos/home1-Images/WhatsApp Image 2023-03-29 at 12.26.07 PM (3).jpeg')} alt="" />
              <img src={require('../photos/home1-Images/118164421.jpg')} alt="" />
            </div>
            <div className="aboutUsSummary1-1" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.24 PM.jpeg')} alt="" />
            </div>
          </div>
          <span>
            Print or outdoor medium are other ways adopted by some, which is again a costly affair
            with no clarity of positive returns from the marketing exercise. So, what is the best way
            to acquire maximum customers which are converted to more revenue and eventually to
            more profits, with zero monetary investment???<br />

            We at <strong>  “Dस्तक Hospitality and Promoters” </strong>
            have introduced a simple but effective solution for this universal problem!!! <br />We have
            found, all the customers like to feel special, privileged and want to be felt pampered
            individually! So, we have reached to a solution, for all the people concerned.


            Our Direct marketing professionals conduct direct marketing ways of ' word of mouth’
            publicity / campaign! Here,  our trained and experienced D.M (Direct Marketing)
            executives approach the target audience (customers) of your city. They meet the
            prospective customers individually at their homes, offices, hot spots etc., and educate
            about the hotel, features, amenities and food, special offer and discounts offered
            through the privilege card. The card is issued to the customer in form of invitation to
            become a Privileged member And these exciting discounts and offers can displayed on
            the screen, that will be redeemed by the customers after subscribing to our website
            portal as a membership plan offering by you (Hotels/Resorts, Restaurants/Bar etc.).
          </span>
        </div>
        <div className='aboutUsSummary1'>
          <span>
            So, ideally to create a membership of around 1000-2000 loyal customers  for your hotel,
            our marketing executives have to meet & present the details of Your Hotel offers, to at
            least
            5, 00,000 to 10, 00,000 people entire city!!!!

            Just imagine the kind of publicity which your
            restaurants gain, when an average of 1000 to 2000 people are being made aware of
            your  Hotels/Resorts, Restaurants/Bar etc. and each individual spending around 10-20
            minutes in average with each customer !!!!!<br />
            The best part is that all the expenses of marketing; like  manpower, invitation, printing,
            food & stay for the executives are wholly borne by us.<br />
            Hoping to give you a very good foot falls from your city and around the Country.
          </span>
          <div className="aboutUsSummaryImgCont">
            <img src={require('../photos/WhatsApp Image 2023-02-24 at 12.59.22 PM (1).jpeg')} alt="" />
          </div>
        </div>
      </div>
      </div>

    </div>
  )
}

export default AboutUs