import React from 'react'
import './project.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
const Project = () => {
  return (
    <div className=' Project'>
      <div className="projectHead">
        <span>Previous Projects</span>
      </div>

      <div className="Project6">
        <div className="ProjectSub6">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.54.13 PM (1).jpeg')} alt='img' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.54.13 PM.jpeg')} alt='img' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.54.14 PM (1).jpeg')} alt='img' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.54.14 PM.jpeg')} alt='img' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.54.15 PM.jpeg')} alt='img' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.27 PM.jpeg')} alt='img' />
            </SwiperSlide>

            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.28 PM (1).jpeg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.28 PM.jpeg')} />
            </SwiperSlide>
             <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.29 PM (1).jpeg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.29 PM (2).jpeg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.55.29 PM.jpeg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../photos/projects/WhatsApp Image 2023-04-05 at 6.56.41 PM.jpeg')} />
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>
  )
}
export default Project